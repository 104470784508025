import React from "react";
import Alberto from "../assets/img/faces/alberto-castano.jpeg";
import Daniel from "../assets/img/faces/dani-heinisch.jpg";
import Carlos from "../assets/img/faces/carlos-alonso.jpg";

import { useTranslation } from "react-i18next";

export default function Team() {
  const { t } = useTranslation("team");
  return (
    <>
      <section className="relative z-10 pt-[150px] overflow-hidden mb-32 text-center">
        <div className="container mx-auto px-4 max-w-[1320px]">
          <div className="flex flex-wrap items-center mx-[-16px]">
            <div className="w-full md:w-8/12 lg:w-7/12 px-4">
              <div className="max-w-[570px] mb-12 md:mb-0">
                <h1
                  className="
                    font-bold text-black
                    dark:text-white
                    text-2xl
                    sm:text-3xl
                    mb-5
                  "
                >
                  {t("title")}
                </h1>
                <p
                  className="
                    font-medium
                    text-base
                    text-body-color
                    leading-relaxed
                  "
                >
                  {t("description")}
                </p>
              </div>
            </div>
            <div className="w-full md:w-4/12 lg:w-5/12 px-4">
              <div className="text-end">
                <ul className="flex items-center md:justify-end">
                  <li className="flex items-center">
                    <a
                      href="/"
                      className="
                        font-medium text-base text-body-color pr-1
                        hover:text-primary
                      "
                    >
                      {t("home")}
                    </a>
                    <span
                      className="
                        block
                        w-2
                        h-2
                        border-t-2
                        border-r-2
                        border-body-color
                        rotate-45
                        mr-3
                      "
                    ></span>
                  </li>
                  <li className="font-medium text-base text-primary">Team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="founders" className="text-center pt-[120px]">
        <div className="container mx-auto px-4 max-w-[1320px]">
          <div
            className="grid gap-x-6 lg:gap-x-12 md:grid-cols-3 wow fadeInUp"
            data-wow-delay=".1s"
          >
            <div className="mb-24 md:mb-0">
              <div className="rounded-lg shadow-lg h-full block bg-white">
                <div className="flex justify-center">
                  <div
                    className="flex justify-center"
                    style={{ marginTop: "-75px" }}
                  >
                    <img
                      src={Daniel}
                      className="rounded-full mx-auto shadow-lg"
                      alt=""
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
                <div className="p-6">
                  <h5 className="text-lg font-bold mb-4">Daniel Heinisch</h5>
                  <p className="mb-6">CEO</p>
                  <ul className="list-inside flex mx-auto justify-center">
                    <a
                      href="https://www.linkedin.com/in/daniel-hugo-heinisch-rodicio-495135ba/"
                      className="px-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 text-blue-600"
                      >
                        <path
                          fill="currentColor"
                          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                        />
                      </svg>
                    </a>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mb-24 md:mb-0">
              <div className="rounded-lg shadow-lg h-full block bg-white">
                <div className="flex justify-center">
                  <div
                    className="flex justify-center"
                    style={{ marginTop: "-75px" }}
                  >
                    <img
                      src={Alberto}
                      className="rounded-full mx-auto shadow-lg"
                      alt=""
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
                <div className="p-6">
                  <h5 className="text-lg font-bold mb-4">Alberto Castaño</h5>
                  <p className="mb-6">CTO</p>
                  <ul className="list-inside flex mx-auto justify-center">
                    <a
                      href="https://www.linkedin.com/in/alberto-castano-4aa710b6/"
                      className="px-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 text-blue-600"
                      >
                        <path
                          fill="currentColor"
                          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                        />
                      </svg>
                    </a>
                  </ul>
                </div>
              </div>
            </div>

            <div className="">
              <div className="rounded-lg shadow-lg h-full block bg-white">
                <div className="flex justify-center">
                  <div
                    className="flex justify-center"
                    style={{ marginTop: "-75px" }}
                  >
                    <img
                      src={Carlos}
                      className="rounded-full mx-auto shadow-lg"
                      alt=""
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
                <div className="p-6">
                  <h5 className="text-lg font-bold mb-4">Carlos Alonso</h5>
                  <p className="mb-6">CIO</p>
                  <ul className="list-inside flex mx-auto justify-center">
                    <a
                      href="https://www.linkedin.com/in/carlos-alonso-huerta-98633b142/"
                      className="px-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 text-blue-600"
                      >
                        <path
                          fill="currentColor"
                          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                        />
                      </svg>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
