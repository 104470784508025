import React from "react";
import SimpleNavbar from "./SimpleNavbar";

import "/node_modules/flag-icons/css/flag-icons.min.css";

import { useTranslation } from "react-i18next";

export default function Navbar() {
  const { t } = useTranslation("navbar");
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <SimpleNavbar>
      <div className="flex px-4 justify-between items-center w-full">
        <div>
          <ul className="block lg:hidden">
            <li className="relative group">
              <a
                href="https://relisten.ai"
                className="
                      text-sm
                      text-dark
                      dark:text-white
                      py-2
                      flex mx-1
                    "
              >
                Relisten
              </a>
            </li>
          </ul>
          <button
            id="navbarToggler"
            aria-label="Mobile Menu"
            className="
                block
                absolute
                right-4
                top-1/2
                translate-y-[-50%]
                lg:hidden
                focus:ring-2
                ring-primary px-3 py-[6px] rounded-lg
              "
            onClick={toggleDrawer}
          >
            <span
              className="
                  relative
                  w-[30px]
                  h-[2px]
                  my-[6px]
                  block
                  bg-dark
                  dark:bg-white
                "
            ></span>
            <span
              className="
                  relative
                  w-[30px]
                  h-[2px]
                  my-[6px]
                  block
                  bg-dark
                  dark:bg-white
                "
            ></span>
            <span
              className="
                  relative
                  w-[30px]
                  h-[2px]
                  my-[6px]
                  block
                  bg-dark
                  dark:bg-white
                "
            ></span>
          </button>
          <nav
            id="navbarCollapse"
            className={`
                absolute py-5
                lg:py-0 lg:px-4
                xl:px-6
                bg-white
                dark:bg-dark
                lg:dark:bg-transparent lg:bg-transparent
                shadow-lg rounded-lg max-w-[250px] w-full
                lg:max-w-full lg:w-full
                right-4 top-full
                lg:block lg:static lg:shadow-none
                ${drawerOpen ? "block" : "hidden"}
              `}
          >
            <ul className="block lg:flex">
              <li className="relative group">
                <a
                  href="https://relisten.ai"
                  className="
                      menu-scroll
                      text-base
                      text-dark
                      dark:text-white
                      group-hover:opacity-70
                      py-2
                      lg:py-6 lg:inline-flex lg:px-0
                      flex mx-8
                      lg:mr-0
                    "
                >
                  Relisten
                </a>
              </li>
              <li className="relative group">
                <a
                  href="services"
                  className="
                      menu-scroll
                      text-base
                      text-dark
                      dark:text-white
                      group-hover:opacity-70
                      py-2
                      lg:py-6 lg:inline-flex lg:px-0
                      flex mx-8
                      lg:mr-0
                    "
                >
                  {t("services")}
                </a>
              </li>
              <li className="relative group">
                <a
                  href="team"
                  className="
                      menu-scroll
                      text-base
                      text-dark
                      dark:text-white
                      group-hover:opacity-70
                      py-2
                      lg:py-6 lg:inline-flex lg:px-0
                      flex mx-8
                      lg:mr-0 lg:ml-8
                      xl:ml-12
                    "
                >
                  {t("team")}
                </a>
              </li>
              <li className="relative group">
                <a
                  href="contact"
                  className="
                      menu-scroll
                      text-base
                      text-dark
                      dark:text-white
                      group-hover:opacity-70
                      py-2
                      lg:py-6 lg:inline-flex lg:px-0
                      flex mx-8
                      lg:mr-0 lg:ml-8
                      xl:ml-12
                    "
                >
                  {t("contact")}
                </a>
              </li>

              <li className="relative group block md:hidden">
                <a
                  href="croupier"
                  className="
                      menu-scroll
                      text-base
                      text-dark
                      dark:text-white
                      group-hover:opacity-70
                      py-2
                      lg:py-6 lg:inline-flex lg:px-0
                      flex mx-8
                      lg:mr-0 lg:ml-8
                      xl:ml-12
                    "
                >
                  Croupier
                </a>
              </li>
              <li className="relative group submenu-item hidden md:block">
                <a
                  className="
                      text-base
                      text-dark
                      dark:text-white
                      group-hover:opacity-70
                      py-2
                      lg:py-6 lg:inline-flex lg:pl-0 lg:pr-4
                      flex mx-8
                      lg:mr-0 lg:ml-8
                      xl:ml-12
                      relative
                      after:absolute
                      after:w-2
                      after:h-2
                      after:border-b-2
                      after:border-r-2
                      after:border-current
                      after:rotate-45
                      lg:after:right-0
                      after:right-1
                      after:top-1/2
                      after:translate-y-[-50%]
                      after:mt-[-2px]
                    "
                >
                  {t("tools")}
                </a>
                <div
                  className="
                      submenu
                      hidden relative
                      lg:absolute
                      w-[250px] top-full
                      lg:top-[110%]
                      left-0 rounded-md
                      lg:shadow-lg
                      p-4
                      lg:block lg:opacity-0 lg:invisible
                      group-hover:opacity-100
                      lg:group-hover:visible lg:group-hover:top-full
                      bg-white
                      dark:bg-dark
                      transition-[top] duration-300
                    "
                >
                  <a
                    href="croupier"
                    className="
                        block text-sm rounded py-[10px] px-4
                        text-dark
                        dark:text-white
                        hover:opacity-70
                      "
                  >
                    Croupier
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </SimpleNavbar>
  );
}
