import { useTranslation } from "react-i18next";

export default function HomeRelisten() {
  const { t } = useTranslation("home", { keyPrefix: "relisten" });

  return (
    <section className="md:px-64 py-8 md:py-16 text-gray-800 text-center lg:text-left">
      <div className="px-6 py-12 md:px-12">
        <div className="grid lg:grid-cols-2 gap-12 flex items-center">
          <div className="mt-12 lg:mt-0">
            <h1 className="text-3xl md:text-5xl font-bold tracking-tight leading-tight mb-12">
              {t("title1")}
              <br />
              <span className="text-blue-600">{t("title2")}</span>
            </h1>
            <p className="text-gray-600">{t("text")}</p>
          </div>
          <div className="mb-12 lg:mb-0">
            <div className="block px-6 py-12 md:px-12 text-center">
              <a
                className="px-8 py-6 text-5xl bg-white font-bold shadow-xl shadow-pink-100 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                href="relisten"
              >
                <span className="text-pink-300">Re</span>
                <span className="text-gray-400">listen</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
