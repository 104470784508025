import React from "react";
import Logo from "../../assets/img/logos/recog-logo.png";
import { languages } from "../language";

import "/node_modules/flag-icons/css/flag-icons.min.css";

import { useTranslation } from "react-i18next";

export default function Navbar({ children }) {
  const { i18n } = useTranslation("navbar");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const setLanguage = (code) => {
    i18n.changeLanguage(code);
    setIsMobileMenuOpen(false);
  };

  const getCurrentLanguage = () => {
    const currentLang = i18n.language;
    const selected = languages.find((l) => l.code === currentLang);
    return selected ? selected : languages.find((l) => l.code === "en");
  };
  return (
    <header
      className="
      header
      bg-transparent
      absolute
      top-0
      left-0
      z-40
      w-full
      flex
      items-center
    "
    >
      <div className="container mx-auto px-4 max-w-[1320px]">
        <div
          className="
          flex mx-[-16px] items-center justify-between relative
        "
        >
          <div className="px-4 w-60 max-w-full">
            <a href="/" className="w-full block py-8 header-logo">
              <img
                src={Logo}
                alt="logo"
                className="w-full dark:hidden"
                style={{ maxWidth: "100%" }}
              />
              <img
                src={Logo}
                alt="logo"
                className="w-full hidden dark:block"
                style={{ maxWidth: "100%" }}
              />
            </a>
          </div>
          {children}
          <div className="flex justify-end items-center pr-20">
            <div className="relative group">
              <a
                className="
                      text-base
                      text-dark
                      dark:text-white
                      group-hover:opacity-70
                      py-2
                      py-6 inline-flex pl-0 pr-4
                      flex mx-8
                      mr-0 ml-8
                      ml-12
                      relative
                      after:absolute
                      after:w-2
                      after:h-2
                      after:border-b-2
                      after:border-r-2
                      after:border-current
                      after:rotate-45
                      after:right-0
                      after:right-1
                      after:top-1/2
                      after:translate-y-[-50%]
                      after:mt-[-2px]
                    "
                onClick={toggleMobileMenu}
              >
                <span
                  className={"fi " + getCurrentLanguage().icon + " fis"}
                ></span>
              </a>
              <div
                className={`submenu ${
                  isMobileMenuOpen
                    ? "block absolute opacity-100 visible top-full"
                    : "hidden"
                }
                md:absolute
                 w-[180px]
                 top-full
                 md:top-[110%]
                 right-0
                 rounded-md
                 lg:shadow-lg
                 p-4
                 md:block
                 md:opacity-0
                 md:invisible
                 group-hover:opacity-100
                 md:group-hover:visible
                 md:group-hover:top-full
                 bg-white dark:bg-dark
                transition-[top]
                duration-300`}
              >
                {languages.map((language, index) => {
                  return (
                    <a
                      key={index}
                      href="javascript:void(0)"
                      className="
                          block text-sm rounded py-[10px] px-4
                          text-dark
                          dark:text-white
                          hover:opacity-70
                        "
                      onClick={() => setLanguage(language.code)}
                    >
                      <span
                        className={"fi " + language.icon + " fis pr-8"}
                      ></span>
                      {language.language}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
