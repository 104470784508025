import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import React, { Suspense, useEffect } from "react";
import Products from "./views/Products";
import Team from "./views/Team";
import Contact from "./views/Contact";
import Legal from "./views/Legal";
import Services from "./views/Services";
import News from "./views/News";
import Croupier from "./views/tools/Croupier";
import WOW from "wowjs";
import WithNavbar from "./components/WithNavbar";
import FAQ from "./views/FAQ";
import Redirect from "./components/Redirect";

function App() {
  useEffect(() => {
    console.log("initilizing wow");
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const withNavbar = (children) => {
    return <WithNavbar>{children}</WithNavbar>;
  };
  return (
    <Suspense fallback="loading">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={withNavbar(<Home />)} />
          <Route exact path="/services" element={withNavbar(<Services />)} />
          <Route exact path="/products" element={withNavbar(<Products />)} />
          <Route exact path="/team" element={withNavbar(<Team />)} />
          <Route
            exact
            path="/contact"
            element={<Redirect url="https://relisten.ai" />}
          />
          <Route exact path="/news" element={withNavbar(<News />)} />
          <Route exact path="/legal" element={withNavbar(<Legal />)} />
          <Route exact path="/croupier" element={<Croupier />} />
          <Route
            exact
            path="/relisten"
            element={<Redirect url="https://relisten.ai/contact" />}
          />
          <Route exact path="/faq" element={withNavbar(<FAQ />)} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
