import React from "react";
import GlobalNavbar from "./Navbars/GlobalNavbar";
import GlobalFooter from "./Footers/GlobalFooter";

export default function WithNavbar({ children }) {
  return (
    <>
      <GlobalNavbar />
      {children}
      <GlobalFooter />
    </>
  );
}
