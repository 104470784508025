import React from "react";
import BgVideo from "../../assets/video/landing.mp4";

import { useTranslation } from "react-i18next";

export default function HomeHeader() {
  const { t } = useTranslation("home", { keyPrefix: "header" });

  return (
    <>
      <section
        id="home"
        className="
        relative pt-[120px] 
        md:pt-[150px] md:pb-[120px]
        xl:pt-[180px] xl:pb-[160px]
        2xl:pt-[300px] 2xl:pb-[300px]
      "
      >
        <div
          className="
        container
        md:mx-16 md:pt-8 md:pb-1
        xl:mx-32 xl:pt-16 xl:pb-4
        max-w-[1320px]
        "
        >
          <div className="flex flex-wrap">
            <div className="w-full px-4">
              <div
                className="
                xl:max-w-[750px] 
                md:max-w-[600px]
                text-center
                md:text-left
                text-left wow fadeInUp"
                data-wow-delay=".2s"
              >
                <h1
                  className="
                  text-black
                  dark:text-white
                  font-bold text-5xl
                  sm:text-5xl
                  md:text-6xl
                  leading-tight
                  sm:leading-tight
                  md:leading-tight
                  mb-5
                "
                >
                  {t("title")}
                </h1>
                <p
                  className="
                  font-medium text-xl
                  md:text-xl
                  leading-relaxed
                  md:leading-relaxed
                  text-body-color
                  dark:text-white dark:opacity-90
                  mb-12
                "
                >
                  {t("subTitle")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute top-0 left-0 z-[-10] min-h-full min-w-full overflow-hidden float-right 
        xl:max-h-[500px]
        md:max-h-[750px]
        md:visible
        invisible"
        >
          <video autoPlay muted loop playsInline className="w-full h-full">
            <source src={BgVideo} type="video/mp4" />
          </video>
        </div>
      </section>
    </>
  );
}
