import React from "react";
import Logo from "../../assets/img/logos/recog-logo.png";
import SecurityPolicy from "../../assets/docs/security-policy.pdf";

import { useTranslation } from "react-i18next";

export default function GlobalFooter() {
  const { t } = useTranslation("footer");
  return (
    <footer
      className="
      relative z-10  pt-[100px]
      wow
      fadeInUp
    "
      data-wow-delay=".1s"
    >
      <div className="container mx-auto px-4 max-w-[1320px]">
        <div className="flex flex-wrap mx-[-16px]">
          <div className="w-full hidden md:block md:w-1/12 lg:w-1/12 xl:w-1/12 px-4" />
          <div className="w-full hidden lg:block lg:w-3/12 xl:w-3/12 px-4">
            <div className="mb-16 max-w-[360px]">
              <a href="/" className="inline-block mb-8">
                <img
                  src={Logo}
                  alt="logo"
                  className="w-full dark:hidden"
                  style={{ maxWidth: "40%" }}
                />
                <img
                  src={Logo}
                  alt="logo"
                  className="w-full hidden dark:block"
                  style={{ maxWidth: "40%" }}
                />
              </a>
              <p
                className="
                text-body-color
                text-base
                font-medium
                leading-relaxed
                mb-9
              "
              >
                {t("description")}
              </p>
            </div>
          </div>

          <div className="w-full md:w-3/12 lg:w-2/1 xl:w-2/12 px-4">
            <div className="mb-8">
              <h2
                className="
                font-bold text-black
                dark:text-white
                text-xl mb-6
              "
              >
                {t("terms.title")}
              </h2>
              <ul>
                <li>
                  <a
                    href="/legal"
                    className="
                    text-base
                    font-medium
                    inline-block
                    text-body-color
                    mb-4
                    hover:text-primary
                  "
                  >
                    {t("terms.legal")}
                  </a>
                </li>
                <li>
                  <a
                    href={SecurityPolicy}
                    className="
                    text-base
                    font-medium
                    inline-block
                    text-body-color
                    mb-4
                    hover:text-primary
                  "
                    download
                  >
                    {t("terms.securityPolicy")}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full md:w-2/12 lg:w-2/12 xl:w-2/12 px-4">
            <div className="mb-8">
              <h2
                className="
                font-bold text-black
                dark:text-white
                text-xl mb-6
              "
              >
                {t("support.title")}
              </h2>
              <ul>
                <li>
                  <a
                    href="contact"
                    className="
                    text-base
                    font-medium
                    inline-block
                    text-body-color
                    mb-4
                    hover:text-primary
                  "
                  >
                    {t("support.contact")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-2/12 lg:w-1/12 xl:w-1/12 px-4">
            <div className="mb-8">
              <h2
                className="
                font-bold text-black
                dark:text-white
                text-xl mb-6
              "
              >
                {t("about.title")}
              </h2>
              <ul>
                <li>
                  <a
                    href="faq"
                    className="
                    text-base
                    font-medium
                    inline-block
                    text-body-color
                    mb-4
                    hover:text-primary
                  "
                  >
                    {t("about.faq")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-3/12 lg:w-2/12 xl:w-2/12 px-4">
            <div className="flex md:items-end md:justify-end mb-8">
              <a
                href="https://www.linkedin.com/company/recog-analytics"
                aria-label="social-link"
                className="text-primary"
              >
                <svg
                  width="26"
                  height="28"
                  viewBox="0 0 17 16"
                  className="fill-current"
                >
                  <path d="M15.2196 0H1.99991C1.37516 0 0.875366 0.497491 0.875366 1.11936V14.3029C0.875366 14.8999 1.37516 15.4222 1.99991 15.4222H15.1696C15.7943 15.4222 16.2941 14.9247 16.2941 14.3029V1.09448C16.3441 0.497491 15.8443 0 15.2196 0ZM5.44852 13.1089H3.17444V5.7709H5.44852V13.1089ZM4.29899 4.75104C3.54929 4.75104 2.97452 4.15405 2.97452 3.43269C2.97452 2.71133 3.57428 2.11434 4.29899 2.11434C5.02369 2.11434 5.62345 2.71133 5.62345 3.43269C5.62345 4.15405 5.07367 4.75104 4.29899 4.75104ZM14.07 13.1089H11.796V9.55183C11.796 8.7061 11.771 7.58674 10.5964 7.58674C9.39693 7.58674 9.222 8.53198 9.222 9.47721V13.1089H6.94792V5.7709H9.17202V6.79076H9.19701C9.52188 6.19377 10.2466 5.59678 11.3711 5.59678C13.6952 5.59678 14.12 7.08925 14.12 9.12897V13.1089H14.07Z" />
                </svg>
              </a>
            </div>
            <div className="mb-8">
              <ul>
                <li className="md:text-right">
                  <p
                    className="
                    text-base
                    font-medium
                    inline-block
                    text-body-color
                    mb-4
                  "
                  >
                    Recog Analytics S.L
                  </p>
                </li>
                <li className="md:text-right">
                  <p
                    className="
                    text-base
                    font-medium
                    inline-block
                    text-body-color
                    mb-4
                  "
                  >
                    Castellana 194, 28046, Madrid, Spain
                  </p>
                </li>
                <li className="md:text-right">
                  <p
                    className="
                    text-base
                    font-medium
                    inline-block
                    text-body-color
                    mb-4
                  "
                  >
                    contact@recog.es
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute right-0 top-14 z-[-1]">
        <svg
          width="55"
          height="99"
          viewBox="0 0 55 99"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.8" cx="49.5" cy="49.5" r="49.5" fill="#959CB1" />
          <mask
            id="mask0_94:899"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="99"
            height="99"
          >
            <circle opacity="0.8" cx="49.5" cy="49.5" r="49.5" fill="#4A6CF7" />
          </mask>
          <g mask="url(#mask0_94:899)">
            <circle
              opacity="0.8"
              cx="49.5"
              cy="49.5"
              r="49.5"
              fill="url(#paint0_radial_94:899)"
            />
            <g opacity="0.8" filter="url(#filter0_f_94:899)">
              <circle cx="53.8676" cy="26.2061" r="20.3824" fill="white" />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_f_94:899"
              x="12.4852"
              y="-15.1763"
              width="82.7646"
              height="82.7646"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="10.5"
                result="effect1_foregroundBlur_94:899"
              />
            </filter>
            <radialGradient
              id="paint0_radial_94:899"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(49.5 49.5) rotate(90) scale(53.1397)"
            >
              <stop stopOpacity="0.47" />
              <stop offset="1" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <div className="absolute left-0 bottom-24 z-[-1]">
        <svg
          width="79"
          height="94"
          viewBox="0 0 79 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.3"
            x="-41"
            y="26.9426"
            width="66.6675"
            height="66.6675"
            transform="rotate(-22.9007 -41 26.9426)"
            fill="url(#paint0_linear_94:889)"
          />
          <rect
            x="-41"
            y="26.9426"
            width="66.6675"
            height="66.6675"
            transform="rotate(-22.9007 -41 26.9426)"
            stroke="url(#paint1_linear_94:889)"
            strokeWidth="0.7"
          />
          <path
            opacity="0.3"
            d="M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L77.1885 68.2073L50.5215 7.42229Z"
            fill="url(#paint2_linear_94:889)"
          />
          <path
            d="M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L76.7963 68.2073L50.5215 7.42229Z"
            stroke="url(#paint3_linear_94:889)"
            strokeWidth="0.7"
          />
          <path
            opacity="0.3"
            d="M17.9721 93.3057L-14.9695 88.2076L46.2077 62.325L77.1885 68.2074L17.9721 93.3057Z"
            fill="url(#paint4_linear_94:889)"
          />
          <path
            d="M17.972 93.3057L-14.1852 88.2076L46.2077 62.325L77.1884 68.2074L17.972 93.3057Z"
            stroke="url(#paint5_linear_94:889)"
            strokeWidth="0.7"
          />
          <defs>
            <linearGradient
              id="paint0_linear_94:889"
              x1="-41"
              y1="21.8445"
              x2="36.9671"
              y2="59.8878"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A6CF7" stopOpacity="0.62" />
              <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_94:889"
              x1="25.6675"
              y1="95.9631"
              x2="-42.9608"
              y2="20.668"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A6CF7" stopOpacity="0" />
              <stop offset="1" stopColor="#4A6CF7" stopOpacity="0.51" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_94:889"
              x1="20.325"
              y1="-3.98039"
              x2="90.6248"
              y2="25.1062"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A6CF7" stopOpacity="0.62" />
              <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_94:889"
              x1="18.3642"
              y1="-1.59742"
              x2="113.9"
              y2="80.6826"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A6CF7" stopOpacity="0" />
              <stop offset="1" stopColor="#4A6CF7" stopOpacity="0.51" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_94:889"
              x1="61.1098"
              y1="62.3249"
              x2="-8.82468"
              y2="58.2156"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A6CF7" stopOpacity="0.62" />
              <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_94:889"
              x1="65.4236"
              y1="65.0701"
              x2="24.0178"
              y2="41.6598"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4A6CF7" stopOpacity="0" />
              <stop offset="1" stopColor="#4A6CF7" stopOpacity="0.51" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </footer>
  );
}
