import React from "react";

import { useTranslation } from "react-i18next";

export default function Legal() {
  const { t } = useTranslation("legal");
  return (
    <>
      <section className="relative z-10 pt-[150px] overflow-hidden">
        <div className="container mx-auto px-4 max-w-[1320px]">
          <div className="flex flex-wrap items-center mx-[-16px]">
            <div className="w-full md:w-8/12 lg:w-7/12 px-4">
              <div className="max-w-[570px] mb-12 md:mb-0">
                <h1
                  className="
                    font-bold text-black
                    dark:text-white
                    text-2xl
                    sm:text-3xl
                    mb-5
                  "
                >
                  {t("title")}
                </h1>
                <p
                  className="
                    font-medium
                    text-base
                    text-body-color
                    leading-relaxed
                  "
                >
                  {t("description")}
                </p>
              </div>
            </div>
            <div className="w-full md:w-4/12 lg:w-5/12 px-4">
              <div className="text-end">
                <ul className="flex items-center md:justify-end">
                  <li className="flex items-center">
                    <a
                      href="/"
                      className="
                        font-medium text-base text-body-color pr-1
                        hover:text-primary
                      "
                    >
                      {t("home")}
                    </a>
                    <span
                      className="
                        block
                        w-2
                        h-2
                        border-t-2
                        border-r-2
                        border-body-color
                        rotate-45
                        mr-3
                      "
                    ></span>
                  </li>
                  <li className="font-medium text-base text-primary">
                    {t("legal")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <span className="absolute top-0 left-0 z-[-1]">
            <svg
              width="287"
              height="254"
              viewBox="0 0 287 254"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.1"
                d="M286.5 0.5L-14.5 254.5V69.5L286.5 0.5Z"
                fill="url(#paint0_linear_111:578)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_111:578"
                  x1="-40.5"
                  y1="117"
                  x2="301.926"
                  y2="-97.1485"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4A6CF7" />
                  <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="absolute right-0 top-0 z-[-1]">
            <svg
              width="628"
              height="258"
              viewBox="0 0 628 258"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.1"
                d="M669.125 257.002L345.875 31.9983L524.571 -15.8832L669.125 257.002Z"
                fill="url(#paint0_linear_0:1)"
              />
              <path
                opacity="0.1"
                d="M0.0716344 182.78L101.988 -15.0769L142.154 81.4093L0.0716344 182.78Z"
                fill="url(#paint1_linear_0:1)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_0:1"
                  x1="644"
                  y1="221"
                  x2="429.946"
                  y2="37.0429"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4A6CF7" />
                  <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_0:1"
                  x1="18.3648"
                  y1="166.016"
                  x2="105.377"
                  y2="32.3398"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4A6CF7" />
                  <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </div>
      </section>
      <section id="legalStatement" className="pt-[120px]">
        <div className="container mx-auto px-4 max-w-[1320px]">
          <div
            className="
              border-b border-white border-opacity-[.15]
            "
          >
            <div className="flex flex-wrap items-center mx-[-16px]">
              <div className="w-full lg:w-1/2 px-4">
                <div
                  className="mb-4 max-w-[570px] wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  <h2
                    className="
                      text-black
                      dark:text-white
                      font-bold text-3xl
                      sm:text-4xl
                      md:text-[45px]
                      lg:text-4xl
                      xl:text-[45px]
                      leading-tight
                      sm:leading-tight
                      md:leading-tight
                      lg:leading-tight
                      xl:leading-tight
                      mb-6
                    "
                  >
                    {t("notice.title")}
                  </h2>
                  <p
                    className="
                      text-body-color text-base
                      sm:text-md
                      leading-relaxed
                      sm:leading-relaxed
                      mb-4
                    "
                  >
                    {t("notice.description1")}
                    <br></br>
                    {t("notice.description2")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cookiesPolicy" className="pt-16">
        <div className="container mx-auto px-4 max-w-[1320px]">
          <div
            className="
              pb-[100px] border-b border-white border-opacity-[.15]
            "
          >
            <div className="flex flex-wrap items-center mx-[-16px]">
              <div className="w-full lg:w-1/2 px-4">
                <div
                  className="mb-12 lg:mb-0 max-w-[570px] wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  <h2
                    className="
                      text-black
                      dark:text-white
                      font-bold text-3xl
                      sm:text-4xl
                      md:text-[45px]
                      lg:text-4xl
                      xl:text-[45px]
                      leading-tight
                      sm:leading-tight
                      md:leading-tight
                      lg:leading-tight
                      xl:leading-tight
                      mb-6
                    "
                  >
                    {t("cookies.title")}
                  </h2>
                  <p
                    className="
                      text-body-color text-base
                      sm:text-md
                      leading-relaxed
                      sm:leading-relaxed
                      mb-11
                    "
                  >
                    <h2>{t("cookies.cookies.header")}</h2>
                    <p>{t("cookies.cookies.text1")}</p>
                    <br></br>
                    <p>{t("cookies.cookies.text2")}</p>
                    <h2>{t("cookies.purpose.header")}</h2>
                    <p>{t("cookies.purpose.text1")}</p>
                    <ul>
                      <li>{t("cookies.purpose.target1")}</li>
                    </ul>
                    <p>{t("cookies.purpose.text2")}</p>
                    <h2>{t("cookies.use_of_cookies.header")}</h2>
                    <p>{t("cookies.use_of_cookies.text1")}</p>
                    <h3>{t("cookies.use_of_cookies.consent")}</h3>
                    <p>{t("cookies.use_of_cookies.text2")}</p>
                    <br></br>
                    <p>{t("cookies.use_of_cookies.text3")}</p>
                    <br></br>
                    <p>{t("cookies.use_of_cookies.text4")}</p>
                    <br></br>
                    <p>{t("cookies.use_of_cookies.text5")}</p>
                    <br></br>
                    <h3>{t("cookies.use_of_cookies.used")}</h3>
                    <br></br>
                    <p>{t("cookies.use_of_cookies.text6")}</p>
                    <br></br>
                    <p>{t("cookies.use_of_cookies.text7")}</p>
                    <br></br>
                    <p>{t("cookies.use_of_cookies.text8")}</p>
                    <br></br>
                    <ul>
                      <li>{t("cookies.use_of_cookies.use1")}</li>
                      <li>{t("cookies.use_of_cookies.use2")}</li>
                      <li>{t("cookies.use_of_cookies.use3")}</li>
                      <li>{t("cookies.use_of_cookies.use4")}</li>
                    </ul>
                    <p>{t("cookies.use_of_cookies.text9")}</p>
                    <br></br>
                    <p>{t("cookies.use_of_cookies.text10")}</p>
                    <br></br>
                    <table>
                      <thead>
                        <tr>
                          {" "}
                          <th>{t("cookies.table_header.header.column1")}</th>
                          <th>
                            {t("cookies.table_header.header.column2")}
                          </th>{" "}
                          <th>{t("cookies.table_header.header.column3")}</th>
                          <th>
                            {t("cookies.table_header.header.column4")}
                          </th>{" "}
                          <th>{t("cookies.table_header.header.column5")}</th>{" "}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("cookies.table_row1.row1.column1")}</td>{" "}
                          <td>{t("cookies.table_row1.row1.column2")}</td>
                          <td>{t("cookies.table_row1.row1.column3")}</td>{" "}
                          <td>{t("cookies.table_row1.row1.column4")}</td>
                          <td>{t("cookies.table_row1.row1.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row2.row2.column1")}</td>{" "}
                          <td>{t("cookies.table_row2.row2.column2")}</td>
                          <td>{t("cookies.table_row2.row2.column3")}</td>{" "}
                          <td>{t("cookies.table_row2.row2.column4")}</td>
                          <td>{t("cookies.table_row2.row2.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row3.row3.column1")}</td>{" "}
                          <td>{t("cookies.table_row3.row3.column2")}</td>
                          <td>{t("cookies.table_row3.row3.column3")}</td>{" "}
                          <td>{t("cookies.table_row3.row3.column4")}</td>
                          <td>{t("cookies.table_row3.row3.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row4.row4.column1")}</td>{" "}
                          <td>{t("cookies.table_row4.row4.column2")}</td>
                          <td>{t("cookies.table_row4.row4.column3")}</td>{" "}
                          <td>{t("cookies.table_row4.row4.column4")}</td>
                          <td>{t("cookies.table_row4.row4.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row5.row5.column1")}</td>{" "}
                          <td>{t("cookies.table_row5.row5.column2")}</td>
                          <td>{t("cookies.table_row5.row5.column3")}</td>{" "}
                          <td>{t("cookies.table_row5.row5.column4")}</td>
                          <td>{t("cookies.table_row5.row5.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row6.row6.column1")}</td>{" "}
                          <td>{t("cookies.table_row6.row6.column2")}</td>
                          <td>{t("cookies.table_row6.row6.column3")}</td>{" "}
                          <td>{t("cookies.table_row6.row6.column4")}</td>
                          <td>{t("cookies.table_row6.row6.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row7.row7.column1")}</td>{" "}
                          <td>{t("cookies.table_row7.row7.column2")}</td>
                          <td>{t("cookies.table_row7.row7.column3")}</td>{" "}
                          <td>{t("cookies.table_row7.row7.column4")}</td>
                          <td>{t("cookies.table_row7.row7.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row8.row8.column1")}</td>{" "}
                          <td>{t("cookies.table_row8.row8.column2")}</td>
                          <td>{t("cookies.table_row8.row8.column3")}</td>{" "}
                          <td>{t("cookies.table_row8.row8.column4")}</td>
                          <td>{t("cookies.table_row8.row8.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row9.row9.column1")}</td>{" "}
                          <td>{t("cookies.table_row9.row9.column2")}</td>
                          <td>{t("cookies.table_row9.row9.column3")}</td>{" "}
                          <td>{t("cookies.table_row9.row9.column4")}</td>
                          <td>{t("cookies.table_row9.row9.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row10.row10.column1")}</td>{" "}
                          <td>{t("cookies.table_row10.row10.column2")}</td>
                          <td>{t("cookies.table_row10.row10.column3")}</td>{" "}
                          <td>{t("cookies.table_row10.row10.column4")}</td>
                          <td>{t("cookies.table_row10.row10.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row11.row11.column1")}</td>{" "}
                          <td>{t("cookies.table_row11.row11.column2")}</td>
                          <td>{t("cookies.table_row11.row11.column3")}</td>{" "}
                          <td>{t("cookies.table_row11.row11.column4")}</td>
                          <td>{t("cookies.table_row11.row11.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row12.row12.column1")}</td>{" "}
                          <td>{t("cookies.table_row12.row12.column2")}</td>
                          <td>{t("cookies.table_row12.row12.column3")}</td>{" "}
                          <td>{t("cookies.table_row12.row12.column4")}</td>
                          <td>{t("cookies.table_row12.row12.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row13.row13.column1")}</td>{" "}
                          <td>{t("cookies.table_row13.row13.column2")}</td>
                          <td>{t("cookies.table_row13.row13.column3")}</td>{" "}
                          <td>{t("cookies.table_row13.row13.column4")}</td>
                          <td>{t("cookies.table_row13.row13.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row14.row14.column1")}</td>{" "}
                          <td>{t("cookies.table_row14.row14.column2")}</td>
                          <td>{t("cookies.table_row14.row14.column3")}</td>{" "}
                          <td>{t("cookies.table_row14.row14.column4")}</td>
                          <td>{t("cookies.table_row14.row14.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row15.row15.column1")}</td>{" "}
                          <td>{t("cookies.table_row15.row15.column2")}</td>
                          <td>{t("cookies.table_row15.row15.column3")}</td>{" "}
                          <td>{t("cookies.table_row15.row15.column4")}</td>
                          <td>{t("cookies.table_row15.row15.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row16.row16.column1")}</td>{" "}
                          <td>{t("cookies.table_row16.row16.column2")}</td>
                          <td>{t("cookies.table_row16.row16.column3")}</td>{" "}
                          <td>{t("cookies.table_row16.row16.column4")}</td>
                          <td>{t("cookies.table_row16.row16.column5")}</td>{" "}
                        </tr>
                        <tr>
                          <td>{t("cookies.table_row17.row17.column1")}</td>{" "}
                          <td>{t("cookies.table_row17.row17.column2")}</td>
                          <td>{t("cookies.table_row17.row17.column3")}</td>{" "}
                          <td>{t("cookies.table_row17.row17.column4")}</td>
                          <td>{t("cookies.table_row17.row17.column5")}</td>{" "}
                        </tr>
                      </tbody>
                    </table>

                    <p>{t("cookies.use_of_cookies.tracking_pixels")}</p>
                    <h2>{t("cookies.change_settings.header")}</h2>
                    <p>{t("cookies.change_settings.text1")}</p>
                    <ul>
                      <li>{t("cookies.change_settings.set1")}</li>
                      <li>{t("cookies.change_settings.set2")}</li>
                      <li>{t("cookies.change_settings.set3")}</li>
                    </ul>
                    <p>{t("cookies.change_settings.text2")}</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="privacyPolicy" className="pt-[120px]">
        <div className="container mx-auto px-4 max-w-[1320px]">
          <div
            className="
              border-b border-white border-opacity-[.15]
            "
          >
            <div className="flex flex-wrap items-center mx-[-16px]">
              <div className="w-full lg:w-1/2 px-4">
                <div
                  className="mb-4 max-w-[570px] wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  <h2
                    className="
                      text-black
                      dark:text-white
                      font-bold text-3xl
                      sm:text-4xl
                      md:text-[45px]
                      lg:text-4xl
                      xl:text-[45px]
                      leading-tight
                      sm:leading-tight
                      md:leading-tight
                      lg:leading-tight
                      xl:leading-tight
                      mb-6
                    "
                  >
                    {t("privacy.policy.header")}
                  </h2>
                  <p
                    className="
                      text-body-color text-base
                      sm:text-md
                      leading-relaxed
                      sm:leading-relaxed
                      mb-4
                    "
                  >
                    <p>{t("privacy.policy.text1")}</p>
                    <br></br>
                    <p>{t("privacy.policy.text2")}</p>
                    <h2>{t("privacy.scope.header")}</h2>
                    <p>{t("privacy.scope.text1")}</p>
                    <h2>{t("privacy.personal_information.header")}</h2>
                    <p>{t("privacy.personal_information.text1")}</p>
                    <h2>{t("privacy.collection.header")}</h2>
                    <p>{t("privacy.collection.text1")}</p>
                    <h3>{t("privacy.collection.provide")}</h3>
                    <p>{t("privacy.collection.text2")}</p>
                    <h3>{t("privacy.collection.automatically")}</h3>
                    <p>{t("privacy.collection.text3")}</p>
                    <h3>{t("privacy.collection.third_parties")}</h3>
                    <p>{t("privacy.collection.text4")}</p>
                    <h2>{t("privacy.use.header")}</h2>
                    <p>{t("privacy.use.text1")}</p>
                    <h3>{t("privacy.use.provision")}</h3>
                    <p>{t("privacy.use.text2")}</p>
                    <h3>{t("privacy.use.communications")}</h3>
                    <p>{t("privacy.use.text3")}</p>
                    <h2>{t("privacy.disclosure.header")}</h2>
                    <p>{t("privacy.disclosure.text1")}</p>
                    <h3>{t("privacy.disclosure.consent")}</h3>
                    <p>{t("privacy.disclosure.text2")}</p>
                    <h3>{t("privacy.disclosure.implicit_consent")}</h3>
                    <p>{t("privacy.disclosure.text3")}</p>
                    <h3>{t("privacy.disclosure.fulfillment")}</h3>
                    <p>{t("privacy.disclosure.text4")}</p>
                    <h3>{t("privacy.disclosure.transactions")}</h3>
                    <p>{t("privacy.disclosure.text5")}</p>
                    <h3>{t("privacy.disclosure.legal")}</h3>
                    <p>{t("privacy.disclosure.text6")}</p>
                    <h2>{t("privacy.minors.header")}</h2>
                    <p>{t("privacy.minors.text1")}</p>
                    <h2>{t("privacy.security.header")}</h2>
                    <p>{t("privacy.security.text1")}</p>
                    <br></br>
                    <p>{t("privacy.security.text2")}</p>
                    <br></br>
                    <p>{t("privacy.security.text3")}</p>
                    <h2>{t("privacy.use_loc_data.header")}</h2>
                    <p>{t("privacy.use_loc_data.text1")}</p>
                    <h2>{t("privacy.protect_info.header")}</h2>
                    <p>{t("privacy.protect_info.text1")}</p>
                    <h2>{t("privacy.access_info.header")}</h2>
                    <p>{t("privacy.access_info.text1")}</p>
                    <h2>{t("privacy.opt_out_choices.header")}</h2>
                    <p>{t("privacy.opt_out_choices.text1")}</p>
                    <br></br>
                    <p>{t("privacy.opt_out_choices.text2")}</p>
                    <h2>{t("privacy.similar_tech.header")}</h2>
                    <p>{t("privacy.similar_tech.text1")}</p>
                    <br></br>
                    <p>{t("privacy.similar_tech.text2")}</p>
                    <br></br>
                    <p>{t("privacy.similar_tech.text3")}</p>
                    <br></br>
                    <p>{t("privacy.similar_tech.text4")}</p>
                    <h2>{t("privacy.links.header")}</h2>
                    <p>{t("privacy.links.text1")}</p>
                    <h2>{t("privacy.surveys.header")}</h2>
                    <p>{t("privacy.surveys.text1")}</p>
                    <h2>{t("privacy.registration.header")}</h2>
                    <p>{t("privacy.registration.text1")}</p>
                    <h2>{t("privacy.orders.header")}</h2>
                    <p>{t("privacy.orders.text1")}</p>
                    <h2>{t("privacy.other_terms.header")}</h2>
                    <p>{t("privacy.other_terms.text1")}</p>
                    <h2>{t("privacy.rights.header")}</h2>
                    <p>{t("privacy.rights.text1")}</p>
                    <br></br>
                    <p>{t("privacy.rights.text2")}</p>
                    <br></br>
                    <p>{t("privacy.rights.text3")}</p>
                    <h2>{t("privacy.contact.header")}</h2>
                    <p>{t("privacy.contact.text1")}</p>
                    <h2>{t("privacy.changes_policy.header")}</h2>
                    <p>{t("privacy.changes_policy.text1")}</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
