export const languages = [
  { language: "Español", code: "es-ES", icon: "fi-es" },
  { language: "English", code: "en", icon: "fi-us" },
  { language: "Français", code: "fr-FR", icon: "fi-fr" },
  { language: "Italiano", code: "it-IT", icon: "fi-it" },
  { language: "Deutsch", code: "de-DE", icon: "fi-de" },
  { language: "Català", code: "ca-ES", icon: "fi-es-ct" },
  { language: "Português", code: "pt-PT", icon: "fi-pt" },
];

export const LANGUAGE_LIST = [];
