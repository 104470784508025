import React, { useState } from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Header from "../../assets/img/croupier.svg";
import CroupierDoctor from "../../assets/img/croupier-doctor.svg";
import CroupierWoman from "../../assets/img/croupier-woman.svg";
import DatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { IoMdHelpCircleOutline } from "react-icons/io";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import SimpleNavbar from "../../components/Navbars/SimpleNavbar";

import "../../assets/demo/croupier.css";

class GuardType {
  constructor(name, number) {
    this.name = name;
    this.number = number;
  }

  name: string;
  number: number;
}

class ModificationType {
  constructor(name, number, guard) {
    this.name = name;
    this.number = number;
    this.guard = guard;
  }

  name: string;
  number: number;
  guard: string;
}

const animatedComponents = makeAnimated();

function Croupier() {
  const [buildTemplateValues, setBuildTemplateValues] = useState({
    month: new Date(),
    guardTypes: [new GuardType("", "")],
    modificationTypes: [new ModificationType("", "", "")],
    reportTitle: "",
    holidays: "",
    specialHolidays: "",
    equateDays: "",
    equateGuards: "",
    validate: {
      guardTypes: [{ name: "has-danger", number: "has-danger" }],
    },
  });

  const [sendTemplateValues, setSendTemplateValues] = useState({
    template: "",
    email: "",
    updateCheck: false,
    validate: {
      templateState: "",
      emailState: "",
    },
  });

  const [advancedOptionOpen, setAdvancedOptionOpen] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);

  const equiDaysOptions = [
    { value: "Lunes", label: "Lunes" },
    { value: "Martes", label: "Martes" },
    { value: "Miércoles", label: "Miércoles" },
    { value: "Jueves", label: "Jueves" },
    { value: "Viernes", label: "Viernes" },
    { value: "Sábado", label: "Sábado" },
    { value: "Domingo", label: "Domingo" },
    { value: "Festivo", label: "Festivo" },
    { value: "Festivo especial", label: "Festivo especial" },
  ];

  const set = (name) => {
    return ({ target: { value } }) => {
      setBuildTemplateValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  function setMonth(date) {
    setBuildTemplateValues((oldValues) => ({ ...oldValues, month: date }));
  }

  function validateGuardType(i, prop) {
    const guardType = buildTemplateValues.guardTypes[i];
    let guardTypeStatus = "has-danger";
    if (
      guardType[prop] !== "" &&
      guardType[prop] !== undefined &&
      guardType[prop] !== null
    ) {
      guardTypeStatus = "has-success";
    }
    buildTemplateValues.validate.guardTypes[i][prop] = guardTypeStatus;
    console.log(guardType[prop], i, prop, guardTypeStatus);
  }

  const setGuardType = (prop, i) => {
    return ({ target: { value } }) => {
      const oldGuardTypes = buildTemplateValues.guardTypes;
      oldGuardTypes[i][prop] = value;
      setBuildTemplateValues((oldValues) => ({
        ...oldValues,
        guardTypes: oldGuardTypes,
      }));
      validateGuardType(i, prop);
    };
  };

  const setModificationType = (prop, i) => {
    return (e) => {
      let value;
      if (prop === "guard") {
        value = e.value;
      } else if (prop === "number") {
        value = e.target.value;
      } else if (prop === "name") {
        value = e.target.value;
      }
      const modificationTypes = buildTemplateValues.modificationTypes;
      modificationTypes[i][prop] = value;
      setBuildTemplateValues((oldValues) => ({
        ...oldValues,
        modificationTypes: modificationTypes,
      }));
    };
  };

  const setEquateDays = (values) => {
    setBuildTemplateValues((oldValues) => ({
      ...oldValues,
      equateDays: values.map((v) => v.value).join(","),
    }));
  };

  const setEquateGuards = (values) => {
    setBuildTemplateValues((oldValues) => ({
      ...oldValues,
      equateGuards: values.map((v) => v.value).join(","),
    }));
  };

  const setTemplate = () => {
    return (e) => {
      getBase64(e.target.files[0]).then((file) => {
        const validate = sendTemplateValues.validate;
        validate.templateState = "has-success";
        setSendTemplateValues((oldValues) => ({
          ...oldValues,
          template: file,
          validate: validate,
        }));
      });
    };
  };

  const setUpdateCheck = () => {
    return () => {
      const updateCheck = sendTemplateValues.updateCheck;
      setSendTemplateValues((oldValues) => ({
        ...oldValues,
        updateCheck: !updateCheck,
      }));
    };
  };

  function validateEmail(value) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validate = sendTemplateValues.validate;
    if (emailRegex.test(value)) {
      validate.emailState = "has-success";
    } else {
      validate.emailState = "has-danger";
    }
    setSendTemplateValues((oldValues) => ({
      ...oldValues,
      validate: validate,
    }));
  }

  const setEmail = () => {
    return ({ target: { value } }) => {
      validateEmail(value);
      setSendTemplateValues((oldValues) => ({ ...oldValues, email: value }));
    };
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const saveFormData = async () => {
    const params = {
      month:
        buildTemplateValues.month.getFullYear().toString() +
        "-" +
        (buildTemplateValues.month.getMonth() + 1).toString(),
      reportTitle: buildTemplateValues.reportTitle,
      holidays: buildTemplateValues.holidays,
      specialHolidays: buildTemplateValues.specialHolidays,
      equateDays: buildTemplateValues.equateDays,
      equateGuards: buildTemplateValues.equateGuards,
    };

    buildTemplateValues.guardTypes.forEach((guardType, index) => {
      params["guardTypeName" + index.toString()] = guardType.name;
      params["guardTypeNumber" + index.toString()] = guardType.number;
    });

    buildTemplateValues.modificationTypes.forEach((modificationType, index) => {
      params["modificationTypeName" + index.toString()] = modificationType.name;
      params["modificationTypeNumber" + index.toString()] =
        modificationType.number;
      params["modificationTypeGuard" + index.toString()] =
        modificationType.guard;
    });

    Object.keys(params).forEach((key) =>
      !params[key] ? delete params[key] : {}
    );

    const queryString = new URLSearchParams(params).toString();

    const response = await fetch(
      process.env.REACT_APP_CROUPIER_URL + "?" + queryString,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_CROUPIER_API_KEY,
          Accept: "*",
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(`Request failed: ${response.status}`);
    }
    const template = (await response.json()).template;
    const a = document.createElement("a");
    a.href = "data:image/png;base64," + template;
    a.download = "template.xlsx";
    a.click();
  };

  const sendTemplate = async () => {
    fetch(
      "https://c18hmyctkg.execute-api.eu-west-1.amazonaws.com/Prod/template",
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_CROUPIER_API_KEY,
          Accept: "*",
        },
        body: JSON.stringify(sendTemplateValues),
      }
    );

    setSendTemplateValues({
      template: "",
      email: "",
      updateCheck: false,
      validate: {
        templateState: "",
        emailState: "",
      },
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault(); // Prevent default submission
    try {
      await saveFormData();
    } catch (e) {
      alert(`Registration failed! ${e.message}`);
    }
  };

  const onUpload = async (event) => {
    event.preventDefault(); // Prevent default submission
    sendTemplate();
    setSuccess(true);
  };

  function appendInputGuard() {
    const newInput = new GuardType("", "");
    const newValidate = { name: "has-danger", number: "has-danger" };
    const validate = buildTemplateValues.validate;
    validate.guardTypes.push(newValidate);
    setBuildTemplateValues((oldValues) => ({
      ...oldValues,
      validate: validate,
    }));
    setBuildTemplateValues((oldValues) => ({
      ...oldValues,
      guardTypes: oldValues.guardTypes.concat([newInput]),
    }));
  }

  function appendInputModification() {
    const newInput = new ModificationType();
    setBuildTemplateValues((oldValues) => ({
      ...oldValues,
      modificationTypes: oldValues.modificationTypes.concat([newInput]),
    }));
  }

  function removeInputGuard() {
    const oldGuardTypes = buildTemplateValues.guardTypes;
    if (oldGuardTypes.length > 1) {
      oldGuardTypes.pop();
      setBuildTemplateValues((oldValues) => ({
        ...oldValues,
        guardTypes: oldGuardTypes,
      }));
    }
  }

  function removeInputModification() {
    const oldModificationTypes = buildTemplateValues.modificationTypes;
    if (oldModificationTypes.length > 1) {
      oldModificationTypes.pop();
      setBuildTemplateValues((oldValues) => ({
        ...oldValues,
        modificationTypes: oldModificationTypes,
      }));
    }
  }

  function renderSuccess() {
    return (
      <div
        className="py-28 relative"
        style={{
          fontFamily: '"Montserrat", "Helvetica Neue", Arial, sans-serif',
        }}
      >
        <div className="xl:max-w-[1200px] md:max-w-[960px] sm:max-w-xl w-full px-3.5 mx-auto">
          <div>
            <h1
              className="text-center justify-center pb-8 uppercase text-croupier text-[7rem] font-bold tracking-[.2rem] mb-0"
              style={{ textShadow: ".3rem .3rem 0 rgba(0, 0, 0, .2)" }}
            >
              <span className="uppercase text-[#444]">Crou</span>pier
              <span className="uppercase text-black text-xs">Alpha</span>
            </h1>
            <h5 className="text-center justify-center pb-8 uppercase text-[#306cad] text-3xl mt-0 leading-6	mb-3.5 font-bold">
              <span className="uppercase text-[#444]">by </span>Recog
            </h5>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <img alt="..." className="img" src={Header} />
              </div>
              <div md="6" style={{ display: "flex", alignItems: "center" }}>
                <div className="content">
                  <h3 className="text-[1.825em] mb-[30px] leading-[1.4em] font-normal mt-0 text-[#444]">
                    Hecho!
                  </h3>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    Te enviaremos un correo una vez los resultados estén listos.
                    Revisa tu bandeja de entrada, no deberiamos de tardar más de
                    un par de minutos.
                  </p>
                  <button
                    type="button"
                    className="bg-croupier cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                    onClick={() => setSuccess(false)}
                  >
                    Volver a Croupier
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isSuccess) {
    return renderSuccess();
  }

  return (
    <>
      <SimpleNavbar />
      <div
        className="py-28 relative"
        style={{
          fontFamily: '"Montserrat", "Helvetica Neue", Arial, sans-serif',
        }}
      >
        <div className="xl:max-w-[1200px] md:max-w-[960px] sm:max-w-xl w-full px-3.5 mx-auto">
          <div>
            <h1
              className="text-center justify-center pb-8 uppercase text-croupier text-[7rem] font-bold tracking-[.2rem] mb-0"
              style={{ textShadow: ".3rem .3rem 0 rgba(0, 0, 0, .2)" }}
            >
              <span className="uppercase text-[#444]">Crou</span>pier
              <span className="uppercase text-black text-xs">Alpha</span>
            </h1>
            <h5 className="text-center justify-center pb-8 uppercase text-[#306cad] text-3xl mt-0 leading-6	mb-3.5 font-bold">
              <span className="uppercase text-[#444]">by </span>Recog
            </h5>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <img
                  alt="..."
                  className="max-w-full rounded-[1px] align-middle"
                  src={Header}
                />
                <YoutubeEmbed embedId="-YzERn6HCbM" />
              </div>
              <div className="mt-12 px-[15px]">
                <div>
                  <h3 className="text-[1.825em] mb-[30px] leading-[1.4em] font-normal mt-0 text-[#444]">
                    Óptimo, gratuito, rápido, sin necesidad de registrarse y con
                    condiciones flexibles.
                  </h3>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    Organiza las guardias de tus residentes en tres sencillos
                    pasos.
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    1. <b>Configura</b> y descarga tu plantilla de Excel.
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    2. <b>Completa</b> el Excel con los nombres de los
                    residentes y sus días bloqueados.
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    3. Escribe su correo electrónico y <b>sube</b> el Excel.
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    <b>¡Listo!</b> Recibirás la organización de tus guardias en
                    el correo (no olvidas comprobar el buzón de spam).
                  </p>
                  <br />
                  <h3 className="text-[1.825em] mb-[30px] leading-[1.4em] font-normal mt-0 text-[#444]">
                    Múltiples funcionalidades disponibles.
                  </h3>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    Existe una gran variedad de posibilidades con las que
                    sacarle el máximo partido a Croupier:{" "}
                  </p>
                  <p>1. Pre-asignación de guardias en calendario inicial. </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    2. Asociación de residentes para que se les asigne la
                    guardia de forma conjunta.{" "}
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    3. Limitación del número máximo y mínimo que pueden hacer
                    los residentes de cada tipo de guardia.{" "}
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    4. Establecimiento de un máximo de guardias asignables en
                    fin de semana.{" "}
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    5. Diferenciación de guardias de especialidad, días de
                    congreso y bloqueos por preferencia de cara al formato
                    final.{" "}
                  </p>
                  <p className="leading-[1.61em] font-light font-[1.2em] mt-0 mb-4">
                    6. Equiparación de días de la semana y tipos de guardia (por
                    cantidad o proporción).
                  </p>
                </div>
              </div>
            </div>
            <div className="my-5">
              <div
                className="bg-[#d4edda] border-[#c3e6cb] relative py-[0.75rem] px-[1.25rem] border-[1px] border-solid rounded mb-4"
                style={{ color: "#155724" }}
              >
                <h4 className="text-[1.5em] leading-[1.45em] mt-[30px] mb-[15px] font-normal">
                  Si necesitas ayuda, ¡grita!
                </h4>
                <p className="font-light text-[1.2em] leading-[1.61em]">
                  Nuestra intención es adecuarnos lo más posible a vuestras
                  necesidades , por lo que es bienvenida cualquier crítica o
                  sugerencia que pueda hacernos mejorar. Si tienes cualquier
                  duda, o necesitas ayuda en alguno de los pasos, ponte en
                  contacto con nosotros por cualquier canal en cualquier
                  momento.
                </p>
                <hr
                  style={{
                    borderTopColor: "#b1dfbb",

                    marginTop: "1rem",
                    marginBottom: "1rem",
                    border: "0",
                    borderTop: "1px solid rgba(0,0,0,.1)",
                  }}
                />
                <p className="font-light text-[1.2em] leading-[1.61em]">
                  Puedes contactar con nosotros por email en croupier@recog.es o
                  dejarnos un mensaje en https://recog.es/contact
                </p>
              </div>
            </div>
            <h6 className="text-[2rem] mt-[7rem] text-center pb-[2rem] uppercase text-croupier font-bold mb-4">
              <span className="text-[#444]">Descargar</span> la plantilla
            </h6>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <img
                  alt="..."
                  className="max-w-full rounded-[1px] align-middle"
                  src={CroupierDoctor}
                />
              </div>
              <div>
                <form
                  action=""
                  onSubmit={onSubmit}
                  className="text-left bg-white p-8 rounded-lg"
                  style={{
                    boxShadow: ".5rem .5rem 0 rgba(22, 160, 133, .2)",
                    border: ".2rem solid #16a085",
                  }}
                >
                  <h3 className="flex flex-row text-[1.825em] mb-[30px] leading-[1.4em]">
                    Configura tu plantilla{" "}
                    <IoMdHelpCircleOutline
                      className="ml-1 my-auto"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenHelp(true)}
                    />
                  </h3>
                  {buildTemplateValues.guardTypes?.map((input, i) => (
                    <div
                      className="text-left mb-[10px] relative"
                      key={"guardType-" + i.toString()}
                    >
                      <label
                        for="exampleEmail"
                        className="mb-0 mt-2"
                        key={"guardTypeLabel-" + i.toString()}
                      >
                        {"Guardia " + (i + 1).toString()}
                      </label>
                      <div
                        className="grid grid-cols-2 gap-4"
                        key={"guardTypeRow-" + i.toString()}
                      >
                        <div
                          className="pr-0"
                          key={"guardTypeNameColumn-" + i.toString()}
                        >
                          <input
                            type="text"
                            placeholder={"Nombre"}
                            className={
                              buildTemplateValues.validate.guardTypes[i]
                                .name === "has-danger"
                                ? `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-[#dc3545]`
                                : `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-[#28a745]`
                            }
                            style={{
                              transition:
                                "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                            }}
                            value={buildTemplateValues.guardTypes[i].name}
                            onChange={setGuardType("name", i)}
                            key={"guardTypeName-" + i.toString()}
                            valid={
                              buildTemplateValues.validate.guardTypes[i]
                                .name === "has-success"
                            }
                            invalid={
                              buildTemplateValues.validate.guardTypes[i]
                                .name === "has-danger"
                            }
                          />
                        </div>
                        <div
                          className="pr-0"
                          key={"guardTypeNameColumn-bis-" + i.toString()}
                        >
                          <input
                            type="number"
                            placeholder={"Residentes por día"}
                            className={
                              buildTemplateValues.validate.guardTypes[i]
                                .name === "has-danger"
                                ? `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-[#dc3545]`
                                : `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-[#28a745]`
                            }
                            style={{
                              transition:
                                "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                            }}
                            value={buildTemplateValues.guardTypes[i].number}
                            onChange={setGuardType("number", i)}
                            key={"guardTypeNumber-" + i.toString()}
                            valid={
                              buildTemplateValues.validate.guardTypes[i]
                                .number === "has-success"
                            }
                            invalid={
                              buildTemplateValues.validate.guardTypes[i]
                                .number === "has-danger"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="grid grid-cols-2 gap-1">
                    <button
                      type="button"
                      className="bg-croupier cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                      onClick={appendInputGuard}
                    >
                      Añadir tipo de guardia
                    </button>
                    <button
                      className="bg-croupier cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                      onClick={removeInputGuard}
                    >
                      Eliminar tipo de guardia
                    </button>
                  </div>
                  <div className="text-left mb-[10px] relative">
                    <label for="month" className="mb-0 mt-2">
                      Mes
                    </label>
                    <DatePicker
                      className="w-full mr-3 border-solid rounded-md border-[1px] text-sm text-[#444] p-2"
                      selected={buildTemplateValues.month}
                      onChange={(date) => setMonth(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                      showTwoColumnMonthYearPicker
                      showPopperArrow={false}
                      closeOnScroll={true}
                      popperModifiers={[
                        {
                          name: "offset",
                          options: {
                            offset: [100, 0],
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                          },
                        },
                      ]}
                    />
                  </div>
                  <div className="text-left mb-[10px] relative pb-4">
                    <button
                      type="button"
                      className="bg-[#888888] cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                      onClick={() => setAdvancedOptionOpen(!advancedOptionOpen)}
                    >
                      Opciones avanzadas
                    </button>
                    <div className={advancedOptionOpen ? "" : "hidden"}>
                      <div className="text-left mb-[10px] relative">
                        <label for="holidays" className="mb-0 mt-2">
                          Título del reporte
                        </label>
                        <input
                          type="text"
                          placeholder={"Título del reporte"}
                          className="block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-grey"
                          style={{
                            transition:
                              "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                          }}
                          value={buildTemplateValues.reportTitle}
                          onChange={set("reportTitle")}
                        />
                      </div>
                      <div className="text-left mb-[10px] relative">
                        <label for="holidays" className="mb-0 mt-2">
                          Festivos
                        </label>
                        <input
                          type="text"
                          placeholder={"Días separados por comas (e.g: 21,25)"}
                          className="block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-grey"
                          value={buildTemplateValues.holidays}
                          onChange={set("holidays")}
                        />
                      </div>

                      <div className="text-left mb-[10px] relative">
                        <label for="holidays" className="mb-0 mt-2">
                          Festivos especiales
                        </label>
                        <input
                          type="text"
                          placeholder={"Días separados por comas (e.g: 21,25)"}
                          className="block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-grey"
                          value={buildTemplateValues.specialHolidays}
                          onChange={set("specialHolidays")}
                        />
                      </div>
                      <div className="text-left mb-[10px] relative">
                        <label for="equiDays" className="mb-0 mt-2">
                          Equiparar días
                        </label>
                        <Select
                          options={equiDaysOptions}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          isSearchable
                          onChange={setEquateDays}
                        />
                      </div>
                      <div className="text-left mb-[10px] relative">
                        <label for="equiGuards" className="mb-0 mt-2">
                          Equiparar guardias
                        </label>
                        <Select
                          options={buildTemplateValues.guardTypes.map((g) => ({
                            value: g.name,
                            label: g.name,
                          }))}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          onChange={setEquateGuards}
                          isMulti
                          isSearchable
                        />
                      </div>

                      {buildTemplateValues.modificationTypes?.map(
                        (input, i) => (
                          <div
                            className="text-left mb-[10px] relative"
                            key={"´modificationType-" + i.toString()}
                          >
                            <label
                              className="mb-0 mt-2"
                              for="exampleEmail"
                              key={"modificationTypeLabel-" + i.toString()}
                            >
                              {"Modificación cantidad residentes " +
                                (i + 1).toString()}
                            </label>
                            <div
                              className="grid grid-cols-3 gap-4"
                              key={"modificationTypeRow-" + i.toString()}
                            >
                              <div
                                className="pr-0"
                                key={
                                  "modificationGuardTypeColumn-" + i.toString()
                                }
                              >
                                <Select
                                  options={buildTemplateValues.guardTypes.map(
                                    (g) => ({
                                      value: g.name,
                                      label: g.name,
                                    })
                                  )}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  onChange={setModificationType("guard", i)}
                                  isSearchable
                                />
                              </div>
                              <div
                                className="pr-0"
                                key={
                                  "modificationTypeNameColumn-" + i.toString()
                                }
                              >
                                <input
                                  type="text"
                                  placeholder={
                                    "Días separados por comas (e.g: 21,25)"
                                  }
                                  className="block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-grey"
                                  value={
                                    buildTemplateValues.modificationTypes[i]
                                      .name
                                  }
                                  onChange={setModificationType("name", i)}
                                  key={"modificationTypeName-" + i.toString()}
                                />
                              </div>
                              <div
                                className="pr-0"
                                key={
                                  "modificationTypeNumberColumn-" + i.toString()
                                }
                              >
                                <input
                                  type="number"
                                  placeholder={"Residentes por día"}
                                  className="block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-grey"
                                  value={
                                    buildTemplateValues.modificationTypes[i]
                                      .number
                                  }
                                  onChange={setModificationType("number", i)}
                                  key={"modificationTypeNumber-" + i.toString()}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      <button
                        type="button"
                        className="bg-croupier cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                        onClick={appendInputModification}
                      >
                        Añadir modificación
                      </button>
                      <button
                        type="button"
                        className="bg-croupier cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                        onClick={removeInputModification}
                      >
                        Eliminar modificación
                      </button>
                    </div>
                  </div>

                  <button
                    className="bg-croupier cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                    type="submit"
                    value="Descargar plantilla"
                    disabled={
                      !buildTemplateValues.validate.guardTypes.every(
                        (gT) =>
                          gT.name === "has-success" &&
                          gT.number === "has-success"
                      )
                    }
                  >
                    Descargar
                  </button>
                </form>
              </div>
            </div>
            <h6 className="text-[2rem] mt-[7rem] text-center pb-[2rem] uppercase text-croupier font-bold mb-4">
              <span className="text-[#444]">Calcular</span> las guardias
            </h6>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <form
                  action=""
                  onSubmit={onUpload}
                  className="text-left bg-white p-8 rounded-lg"
                  style={{
                    boxShadow: ".5rem .5rem 0 rgba(22, 160, 133, .2)",
                    border: ".2rem solid #16a085",
                  }}
                >
                  <h3 className="flex flex-row text-[1.825em] mb-[30px] leading-[1.4em]">
                    Obtener los resultados
                  </h3>
                  <div className="text-left mb-[10px] relative">
                    <label className="mb-0 mt-2" for="email">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      placeholder="Introduce tu email"
                      type="email"
                      className={
                        buildTemplateValues.validate.emailState === "has-danger"
                          ? `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-[#dc3545]`
                          : `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem] border-[1px] border-solid border-[#28a745]`
                      }
                      style={{
                        transition:
                          "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                      }}
                      onChange={setEmail()}
                    />
                    <div
                      className={
                        sendTemplateValues.validate.emailState === "has-danger"
                          ? "w-full mt-1 text-[80%] text-[#dc3545]"
                          : "hidden"
                      }
                    >
                      ¡Espera! Parece que algo pasa con tu email. Introduce una
                      dirección de email correcta.
                    </div>
                    <div
                      className={
                        sendTemplateValues.validate.emailState === "has-success"
                          ? "w-full mt-1 text-[80%] text-[#28a745]"
                          : "hidden"
                      }
                    >
                      ¡Perfecto! Te enviaremos los resultados a esa dirección.
                    </div>
                  </div>
                  <label className="mb-0 mt-2" for="exampleFile">
                    Plantilla
                  </label>
                  <input
                    id="exampleFile"
                    name="file"
                    type="file"
                    onChange={setTemplate("template")}
                    className={
                      buildTemplateValues.validate.emailState === "has-danger"
                        ? `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem]`
                        : `block bg-clip-padding bg-transparent w-full mr-[0.7rem] rounded-[0.5rem] text-[0.85rem] text-[#444] p-[0.5rem]`
                    }
                    style={{
                      transition:
                        "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                    }}
                  />
                  <small className="text-[#6c757d] block mt-1 text-[12px]">
                    Sube aquí tu plantilla con la información de tu hospital y
                    sus residentes
                  </small>
                  <div
                    className={
                      sendTemplateValues.validate.emailState === "has-danger"
                        ? "w-full mt-1 text-[80%] text-[#dc3545]"
                        : "hidden"
                    }
                  >
                    ¡Ups! Necesitamos que subas el fichero con la plantilla
                    rellena.
                  </div>
                  <div className="mb-0">
                    <button
                      className="bg-croupier cursor-pointer border-[2px] leading-[1.35em] my-[10px] mx-[1px] rounded-[0.1875em] py-[11px] px-[22px] font-light border-none text-xs text-white"
                      type="submit"
                      value="Download template"
                      disabled={
                        sendTemplateValues.validate.emailState !==
                          "has-success" ||
                        sendTemplateValues.validate.templateState !==
                          "has-success"
                      }
                    >
                      Enviar
                    </button>
                  </div>
                  <small className="text-[#6c757d] block mt-1 text-[12px]">
                    Haciendo click en enviar acepto la política de privacidad de
                    Recog
                  </small>
                  <div className="flex flex-row text-left mb-[10px] relative">
                    <label className="mb-0 mt-2" check>
                      <input
                        type="checkbox"
                        onChange={setUpdateCheck("template")}
                      />
                    </label>
                    <small className="text-[#6c757d] block mt-1 text-[12px] ml-3">
                      Deseo recibir actualizaciones por correo electrónico de
                      Croupier y Recog Analytics.
                    </small>
                  </div>
                </form>
              </div>
              <div>
                <img alt="..." className="img" src={CroupierWoman} />
              </div>
            </div>
          </div>
        </div>
        <Modal
          centered
          fullscreen="md"
          scrollable
          size="lg"
          toggle={() => setOpenHelp(false)}
          isOpen={openHelp}
        >
          <ModalHeader toggle={() => setOpenHelp(false)}>
            Configure su plantilla modelo
          </ModalHeader>
          <ModalBody>
            <b>Guardia 1...N:</b> Configure todas los tipos de guardia
            (Traumatología, Interna...) con el respectivo número de residentes
            por día para esa guardia.
            <br />
            <b>Título del reporte:</b> El título que aparecerá en el reporte
            Excel solución.
            <br />
            <b>Festivos:</b> Festivos en el mes indicado, separados por coma y
            sin espacio
            <br />
            <b>Festivos especiales:</b> Festivos especiales en el mes indicado,
            separados por coma y sin espacio
            <br />
            <b>Días a ecualizar:</b> Qué días se deben priorizar para intentar
            que los residentes tengan un número similar de guardias en estos
            días (por ejemplo, seleccionar sábados, domingos, festivos y
            festivos especiales)
            <br />
            <b>Guardias a ecualizar:</b> Qué tipo de guardias se deben priorizar
            para intentar que los residentes tengan un número similar de
            guardias de dicho tipo
          </ModalBody>
          <ModalFooter>
            <button type="button" onClick={() => setOpenHelp(false)}>
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Croupier;
