import React from "react";
import HomeHeader from "./home/HomeHeader";
import HomeServices from "./home/HomeServices";
import HomeRelisten from "./home/HomeRelisten";

export default function Home() {
  return (
    <>
      <HomeHeader />
      <HomeRelisten />
      <HomeServices />
    </>
  );
}
